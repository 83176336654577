@tailwind base;
@tailwind components;
@tailwind utilities;

/* adding fonts below */
/* @font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Light.ttf) format("truetype");
  font-weight: 300;
  font-style: normal;
} */
@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-SemiBold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-Bold.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url(./assets/fonts/Raleway-ExtraBold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* adding fonts above */

html, body, #root{
  height: 100%;
}

.bg-gradient{
  background: rgb(52,56,193);
  background: -moz-linear-gradient(104deg, rgba(52,56,193,1) 65%, rgba(13,179,255,1) 100%);
  background: -webkit-linear-gradient(104deg, rgba(52,56,193,1) 65%, rgba(13,179,255,1) 100%);
  background: linear-gradient(104deg, rgba(52,56,193,1) 65%, rgba(13,179,255,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#3438c1",endColorstr="#0db3ff",GradientType=1);
}
.bg-gradient-vertical{
  /* background: rgb(9,57,83);
  background: -moz-linear-gradient(0deg, rgba(9,57,83,1) 0%, rgba(79,114,132,1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(9,57,83,1) 0%, rgba(79,114,132,1) 100%);
  background: linear-gradient(0deg, rgba(9,57,83,1) 0%, rgba(79,114,132,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#093953",endColorstr="#4f7284",GradientType=1); */
  background: rgb(0,0,0);
background: -moz-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(9,57,83,1) 51%, rgba(79,114,132,1) 100%);
background: -webkit-linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(9,57,83,1) 51%, rgba(79,114,132,1) 100%);
background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(9,57,83,1) 51%, rgba(79,114,132,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#4f7284",GradientType=1);
}

/* //scroll bar */
/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #d1d1d1 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: #ffffff;
}

*::-webkit-scrollbar-thumb {
  background-color: #d1d1d1;
  border-radius: 10px;
  border: 4px solid #ffffff;
}
/* //scroll bar */

.big-shadow{
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.1);
}

.vertical-shadow{
  box-shadow: 0 -10px 10px 0px rgba(20,20,20,0.2);
}
.bottom-shadow{
  box-shadow: 0 15px 10px 0px rgba(20,20,20,0.2);
}

.container-blur{
  filter: blur(10px);
  opacity:40px;
}

.my-skew:after{
  @apply content-[""] bg-white h-24 w-2/4 absolute top-[-48%] left-0 transform -skew-x-12  rounded-tl-[150px] vertical-shadow
}
.my-skew:before{
  @apply content-[""] bg-white h-24 w-2/4 absolute top-[-48%] right-0 transform skew-x-12 rounded-tr-[150px] vertical-shadow
}

.inset-bottom-shadow{
  box-shadow: 0px -44px 13px -3px rgba(171,163,163,0.54) inset;
  -webkit-box-shadow: 0px -44px 13px -3px rgba(171,163,163,0.54) inset;
  -moz-box-shadow: 0px -44px 13px -3px rgba(171,163,163,0.54) inset;
  
}

.icon-image {
  -webkit-filter: drop-shadow(0px 3px 4px rgba(0,0,0,0.4));
    filter: drop-shadow(0px 3px 4px rgba(0,0,0,0.4));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
}

.btn-shadow{
  box-shadow: 0 2px 15px 0px rgba(0,0,0,0.2);
}

.export-shadow{
  box-shadow: 0 2px 2px 0px rgba(0,0,0,0.2)
}

/* inputs */
.input-text{
  @apply w-full text-sm outline-none transition-all origin-center focus:border-primary border-2 border-gray-300 py-2 px-4 rounded-md focus:outline-none
}

.input-text-lg{
  @apply font-semibold w-full text-base outline-none transition-all origin-center focus:border-primary border-2 border-gray-300 py-3 px-3 rounded-md focus:outline-none
}

.input-text-sm{
  @apply font-semibold w-full text-xs outline-none transition-all origin-center focus:border-primary border-[1px] border-gray-300 py-2 px-1 rounded-md focus:outline-none
}

.input-text-borderless{
  @apply font-semibold w-full text-sm outline-none transition-all origin-center text-primary focus:border-primary border-gray-300 focus:outline-none
}

.input-text-active{
  @apply border-green-500
}
.input-text-error{
  @apply border-red-500
}
.input-text-error ~ label{
  @apply text-red-500
}
/* inputs */

.btn{
  @apply select-none transition-all text-sm btn-shadow font-semibold focus:border-2 focus:outline-none rounded-md py-4 capitalize px-5 disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop disabled:shadow-none
}

.btn-sm {
  @apply select-none transition-all btn-shadow font-semibold focus:outline-none rounded-md py-1 px-2 sm:py-2 sm:px-3 text-[10px] sm:text-sm border-[1px] border-transparent disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop disabled:shadow-none
}

.btn-primary{
  @apply hover:bg-secondary active:bg-white  active:border-primary active:text-primary bg-primary text-white disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}
.btn-danger{
  @apply hover:bg-red-300 focus:bg-white  focus:border-red-500 focus:text-red-500 bg-red-500 text-white disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}
.btn-primary-outline{
  @apply hover:bg-secondary/20 focus:bg-white border-[1px] border-primary focus:text-primary  text-primary disabled:bg-gray-300 disabled:text-gray-100 disabled:cursor-no-drop
}

.shadow-container{
  box-shadow: 0 0 5px 0px rgba(0,0,0,0.2);
}

/* PHONE FIELD  */
.react-tel-input .form-control{
  @apply text-base font-medium py-4 h-auto leading-none w-full border-2 border-gray-300 !important
}
.react-tel-input .flag-dropdown{
  @apply border-2 border-gray-300 !important
}

.react-tel-input.phone-drop-d .form-control{
  @apply border-green-500 !important
}
.react-tel-input.phone-drop-d .flag-dropdown{
  @apply border-green-500 !important
}
.react-tel-input.phone-drop-error .flag-dropdown, .react-tel-input.phone-drop-error .form-control{
  @apply border-red-500 !important
}
/* PHONE FIELD  */


.pagination{
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #043c7b; */
  margin-top: 20px;
  /* color: #fff; */
  color: #093953;
  width: 100%;
  border-radius: 20px;
  padding: 10px 10px;
  min-width: 300px;
  /* max-width: 400px; */
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;
  @apply lg:w-2/4 
}

.pagination li {
  margin: 0 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .active{
  border: 1px solid #093953;
}

@media (min-width: 1024px){
  .pagination {
    width: 100%;
  }
}

.shadow-top{
  box-shadow: 0 -4px 4px 0px rgba(0,0,0,0.2);
}

.qr-container canvas{
  max-width: 100%;
}


/* TAGGABLE */
.my-tags .tags-input {
  @apply border-[2px] rounded-md border-gray-300 text-base w-full focus:outline-none
}

.my-tags .tags-input:focus-within, .my-tags-active .tags-input{
@apply border-primary
}
.my-tags-error .tags-input{
@apply border-red-500
}

.my-tags .tags-input input {
  background: transparent;
  border: 0;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
  margin-top: 1px;
  outline: none;
  padding: 5px;
  width: 100%;
}
.my-tags .tag {
  @apply rounded-md py-1 px-2 bg-primary text-white border-none inline-flex items-center 
}

.my-tags button.remove {
  display: none;
  margin-left: 3px;
  margin-bottom: 12px ;
  padding: 0;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 50px;
  border: none;
  outline: none;
  user-select: none;
}
.my-tags .tag:hover button.remove {
@apply block bg-transparent ml-2 
}



.camer-button-shaking {
  animation: shake 0.3s 5;
}

@keyframes shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  25% { transform: translate(5px, 5px) rotate(5deg); }
  50% { transform: translate(0, 0) rotate(0eg); }
  75% { transform: translate(-5px, 5px) rotate(-5deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}


.stat-box:before{
  content: "";
  @apply left-2/4 -translate-x-2/4 h-full w-10/12 absolute bottom-0 translate-y-[10px] bg-white/40 rounded-xl
}

.bg-primary-gradient{
  background: rgb(37,148,207);
  background: -moz-linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  background: -webkit-linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  background: linear-gradient(98deg, rgba(37,148,207,1) 27%, rgba(8,48,73,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2594cf",endColorstr="#083049",GradientType=1);
  color: white;
}

.braintree-dropin {
  font-family: Raleway !important;
}
.braintree-options-list{
  background: #ffffff !important;
  border-color: #ffffff !important;
}
.braintree-option{
  padding: 10px;
  border-color: #ffffff !important;
  margin-top: 2px;
}
.braintree-option:hover {
  /* background: #093953; */
  border-radius: 20px !important;
  color: #ffffff;
}


.text-primary-gradient {
  background: #2594CF;
  background: -webkit-linear-gradient(to right, #2594CF 20%, #083049 100%);
  background: -moz-linear-gradient(to right, #2594CF 20%, #083049 100%);
  background: linear-gradient(to right, #2594CF 20%, #083049 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.reactour__popover {
  padding: 0px !important;
  border-bottom-left-radius:18px;
  border-bottom-right-radius:18px;
  border-top-right-radius:20px;
  border-top-left-radius:20px;
  top: 20px !important;
  left: -21px !important;
  /* right: 10px p !important; */
}
.triangle-up {
	width: 0;
	height: 0;
	border-left: 20px solid transparent;
	border-right: 20px solid transparent;
	border-bottom: 14px solid #ffffff;
}

.EmojiPickerReact .epr-main {
  z-index: 20 !important;
}

/* looper */
@keyframes slideAnimation {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

.looper {
  width: 100%;
  overflow: hidden;
}

.looper__innerList {
  display: flex;
  justify-content: space-between;
  width: fit-content;
}

.looper__innerList[data-animate="true"] .looper__listInstance {
  animation: slideAnimation linear infinite;   
}

.looper__listInstance {
  display: flex;
  align-items: center;
  width: max-content;

  animation: none;
}
/* looper */

.color-selection .react-colorful {
  height: 240px;
  width: 100%
}

.triangle-up {
	width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-bottom: 50px solid #555;
}

.shadow-gray-heavy{
  box-shadow: 0px 0 15px 0 rgba(0,0,0,0.2);
}